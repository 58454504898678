function accordion() {
    var container = $(".accordion > div");
    var trigger = $(container).find(".accordion__header");

    if ($(container).length > 0) {

        //uses jquery.collapsable.js for better accessibility 
        $(trigger).collapsible();

        $(trigger).on("click", function () {
            const self = this;

            $(self)
                .parent().toggleClass("active")
                .siblings().removeClass("active");
        });

        $(document).on("click", e => {
            if (!$(e.target).closest(container).length && !$(e.target).is(container)) {
                $(trigger)
                    .parent().removeClass("active")
                    .siblings().removeClass("active");
            }
        });
    }
}

$(() => {
    accordion();
});